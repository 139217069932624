// @flow
import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'
import {
  Layout,
  Footer,
  Navbar,
  BackgroundImageShort,
  PageRow,
  Heading,
} from 'src/common/components'

import HeadTags from 'src/components/HeadTags'
import StandardContent from 'src/components/ContentRow/StandardContent'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {
  data: Object,
  pageContext: PageContext,
}

const StandardPage = (props: Props) => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: { meta, intro },
      },
      footer: {
        frontmatter: { footer },
      },
    },
    pageContext,
  } = props

  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImageShort />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow firstRow>
              <Heading text={intro.heading} />
              <StandardContent>{html}</StandardContent>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(StandardPage)

export const StandardPageQuery = graphql`
  query StandardQuery($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageType
        ...MetaFragment
        intro {
          heading
          description
        }
      }
    }
    ...FooterFragment
  }
`
